<template>
  <div>
    <b-card :class="$store.state.appConfig.layout.skin === 'dark' ? 'dark-card rounded-lg' : 'rounded-lg'">
      <b-row>
        <b-col md="1"></b-col>
        <b-col md="10">
          <b-toast
            primary
            no-auto-hide
            noCloseButton
            v-model="showToast"
            append-toast
            b-toaster-bottom-center
            id="example-toast"
          >
            <template #toast-title>
              <div class="d-flex flex-grow-1 align-items-center mr-1 pt-2">
                <strong class="mr-auto">Uploading ...</strong>
              </div>
            </template>
            <div v-if="uploadProgress" class="progress-wrapper">
              <b-card-text class="mb-0">{{ uploadProgress ? uploadProgress + "%" : "" || "" }}</b-card-text>
              <b-progress v-model="uploadProgress" max="100" />
            </div>
          </b-toast>

          <b-form class="chat-app-form" ref="form" @submit.prevent="submit">
            <validation-observer class="w-100" ref="TopicForm" #default="{invalid}">
              <b-row class="pt-3">
                <b-col md="6" class="mb-2">
                  <validation-provider #default="{ errors }" name="name" vid="name" rules="required">
                    <b-form-group label="Topic Name *" label-for="name">
                      <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-input
                          autocomplete="off"
                          v-model="form.name"
                          placeholder="Enter Topic Name"
                          id="name"
                          class="custom-field"
                        />
                      </b-input-group>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="module_id"
                    vid="module_id"
                    rules="required"
                  >
                    <b-form-group label="Topic Module *" label-for="module_id">
                      <v-select
                        v-model="form.module_id"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :options="modules"
                        id="module_id"
                        class="w-100 mb-0 custom-field"
                        :reduce="(val) => val.id"
                        placeholder="Select Module"
                        :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                      >
                        <template
                          v-slot:option="option"
                        >{{ $i18n.locale == "ar" ? option.name_local : option.name }}</template>
                        <template #selected-option="{ name, name_local }">
                          <div style="display: flex; align-items: baseline">
                            <strong v-if="$i18n.locale == 'ar'">{{ name_local }}</strong>
                            <strong v-else>{{ name }}</strong>
                          </div>
                        </template>
                        <template #no-options>{{ $t("noMatching") }}</template>
                      </v-select>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                    md="6"
                    class="mb-2"
                    v-if="!editCoverImage && id != null && item && item.cover_image"
                  >
                    <div 
                      class="d-flex align-items-center mb-1 cursor-pointer"
                      @click="editCoverImage = true"
                    >
                      <feather-icon 
                        :tooltip="'none'" 
                        icon="EditIcon" 
                        size="20"
                        class="cursor-pointer text-link mr-1" 
                        v-b-tooltip.hover.top="'Edit Image'"
                      />
                      <small>Edit Cover Image</small>
                    </div>
                    <img :src="item.cover_image" alt="" width="100%">
                  </b-col>
                <b-col
                  md="6"
                  class="mb-2"
                  v-if="editCoverImage || id == null ||(item && item.cover_image == null)"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="cover"
                      vid="cover"
                      rules="required"
                  >
                  <b-form-group label="Topic Cover Photo" label-for="cover">
                    <b-form-file
                      id="cover"
                      v-model="form.cover_image"
                      :state="Boolean(form.cover_image)"
                      accept="image/*"
                      class="custom-field"
                    />
                  </b-form-group>
                  <small class="text-danger float-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="6" class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    vid="description"
                    rules="required"
                  >
                    <b-form-group label="Topic description *" label-for="description">
                      <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-textarea
                          id="textarea-default"
                          v-model="form.description"
                          placeholder="Enter Topic Description"
                          class="custom-field"
                          rows="1"
                        ></b-form-textarea>
                      </b-input-group>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- SubTopics Container -->
                <b-col md="12" class="mb-2">
                  <b-row>
                    <b-card
                      class="mb-2 w-100"
                      v-for="(subTopic, subTopicIndex) in form.subTopics"
                      :id="subTopic.id"
                      :key="subTopic.id"
                      ref="row"
                    >
                      <b-row>
                        <!-- Close Card -->
                        <a
                          v-if="subTopicIndex != 0"
                          class="card-close d-flex justify-content-center shadow text-danger"
                          aria-label="Close this dialog"
                          v-b-tooltip.hover.top="'Delete Sub-Topic'"
                          @click="removeSubTopic(subTopicIndex)"
                        >
                          <feather-icon icon="XIcon" class="font-weight-bolder" size="20" />
                        </a>
                        <b-col md="12" class="mb-2">
                          <validation-provider
                            #default="{ errors }"
                            name="Type"
                            :vid="`type${subTopicIndex}`"
                            rules="required"
                          >
                            <b-form-group label="Type *" label-for="type">
                              <b-input-group class="input-group-merge form-send-message mr-1">
                                <v-select
                                  v-model="form.subTopics[subTopicIndex].type"
                                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                  :options="subTopicsLookups"
                                  :disabled="form.subTopics[subTopicIndex].type == 3 && form.subTopics.length > 1"
                                  class="w-100 mb-0 custom-field"
                                  :reduce="(val) => val.id"
                                  placeholder="Type"
                                  :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                                >
                                  <template
                                    v-slot:option="option"
                                  >{{ $i18n.locale == "ar" ? option.name_local : option.name }}</template>
                                  <template #selected-option="{ name, name_local }">
                                    <div style="display: flex; align-items: baseline">
                                      <strong v-if="$i18n.locale == 'ar'">{{ name_local }}</strong>
                                      <strong v-else>{{ name }}</strong>
                                    </div>
                                  </template>
                                  <template #no-options>{{ $t("noMatching") }}</template>
                                </v-select>
                              </b-input-group>
                            </b-form-group>
                            <small class="text-danger float-left">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!-- PDF -->
                        <b-col md="12" class="mb-2" v-if="(editPDF[subTopicIndex] || id == null) && form.subTopics[subTopicIndex].type == 6">
                          
                          <validation-provider
                            #default="{ errors }"
                            name="PDF"
                            :vid="`pdf${subTopicIndex}`"
                            rules="required"
                          >
                          <b-form-group label="PDF *" label-for="pdf">
                            <b-form-file
                              id="pdf"
                              v-model="form.subTopics[subTopicIndex].pdf"
                              :state="Boolean(form.subTopics[subTopicIndex].pdf)"
                              accept=".pdf"
                              class="custom-field"
                            />
                          </b-form-group>
                            <small class="text-danger float-left">{{ errors[0] }}</small>
                          </validation-provider>
                          <validation-provider
                            #default="{ errors }"
                            name="attach PDF"
                            :vid="`pdf${subTopicIndex}`"
                            rules="required"
                          >
                          <b-form-group label="Require attach pdf" label-for="pdf">
                            <b-form-radio-group v-model="form.subTopics[subTopicIndex].attach" :options="[{ text: 'Required', value: '1' }, { text: 'Not Required', value: '0' }]">
                            </b-form-radio-group>
                          </b-form-group>
                            <small class="text-danger float-left">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col
                          md="12"
                          class="mb-2"
                          v-if="!editPDF[subTopicIndex] && id != null &&item &&  item.subTopics && item.subTopics[subTopicIndex].pdf"
                        >
                          <div 
                            class="d-flex align-items-center mb-1 cursor-pointer"
                            @click="edit_pdf(subTopicIndex)"
                          >
                            <feather-icon 
                              :tooltip="'none'" 
                              icon="EditIcon" 
                              size="20"
                              class="cursor-pointer text-link mr-1" 
                              v-b-tooltip.hover.top="'Edit PDF'"
                            />
                            <small>Edit PDF</small>
                          </div>
                          <h6>{{ item.subTopics && item.subTopics[subTopicIndex].pdf }}</h6>
                        </b-col>
                        <!-- Image -->
                        <b-col md="12" class="mb-2" v-if="(editImage[subTopicIndex] || id == null) && form.subTopics[subTopicIndex].type == 4">
                          
                          <validation-provider
                            #default="{ errors }"
                            name="Image"
                            :vid="`image${subTopicIndex}`"
                            rules="required"
                          >
                          <b-form-group label="Image *" label-for="image">
                            <b-form-file
                              id="image"
                              v-model="form.subTopics[subTopicIndex].image"
                              :state="Boolean(form.subTopics[subTopicIndex].image)"
                              accept="image/*"
                              class="custom-field"
                            />
                          </b-form-group>
                            <small class="text-danger float-left">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col
                          md="12"
                          class="mb-2"
                          v-if="!editImage[subTopicIndex] && id != null &&item &&  item.subTopics && item.subTopics[subTopicIndex].image"
                        >
                          <div 
                            class="d-flex align-items-center mb-1 cursor-pointer"
                            @click="edit_image(subTopicIndex)"
                          >
                            <feather-icon 
                              :tooltip="'none'" 
                              icon="EditIcon" 
                              size="20"
                              class="cursor-pointer text-link mr-1" 
                              v-b-tooltip.hover.top="'Edit Image'"
                            />
                            <small>Edit Image</small>
                          </div>
                          <img :src="item.subTopics[subTopicIndex].image" alt="" width="100%">
                        </b-col>
                        <!-- Text -->
                        <b-col md="12" class="mb-2" v-if="form.subTopics[subTopicIndex].type == 5">
                          <validation-provider
                            #default="{ errors }"
                            name="Text"
                            :vid="`text${subTopicIndex}`"
                            rules="required"
                          >
                          <b-form-group 
                            label="Text *" 
                            label-for="text"
                          >
                            <quill-editor
                              v-model="form.subTopics[subTopicIndex].text"
                              :options="snowOption"
                            >
                              <div
                                id="toolbar"
                                slot="toolbar"
                              >

                              <select class="ql-font">
                                <option selected="selected" />
                                <option value="serif" />
                                <option value="monospace" />
                              </select>
                                <!-- Add a bold button -->
                                <button class="ql-bold">
                                  Bold
                                </button>
                                <button
                                  class="ql-underline"
                                  value="super"
                                />
                                <button class="ql-italic">
                                  Italic
                                </button>
                                <button
                                  class="ql-list"
                                  value="ordered"
                                />
                                <button
                                  class="ql-list"
                                  value="bullet"
                                />
                                
                                <!-- Add font size dropdown -->
                                <select class="ql-size">
                                  <option value="small" />
                                  <!-- Note a missing, thus falsy value, is used to reset to default -->
                                  <option selected />
                                  <option value="large" />
                                  <option value="huge" />
                                </select>

                                <select class="ql-header">
                                  
                                </select>

                                <button
                                  class="ql-list"
                                />

                                <!-- Add subscript and superscript buttons -->
                                <button
                                  class="ql-script"
                                  value="sub"
                                />
                                <button
                                  class="ql-script"
                                  value="super"
                                />

                                <button
                                  class="ql-link"
                                  value="super"
                                />

                                <select class="ql-align">
                                  
                                </select>

                                <select class="ql-color">
                                  
                                </select>

                                <select class="ql-background">
                                  
                                </select>

                                <!-- You can also add your own -->
                                <!-- <button
                                  class="w-auto btn btn-primary"
                                  @click="customButtonClick"
                                >
                                  Click here
                                </button> -->
                              </div>
                            </quill-editor>
                          </b-form-group>
                            <small class="text-danger float-left">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!-- ThingLink -->
                        <b-col md="12" class="mb-2" v-if="form.subTopics[subTopicIndex].type == 7">
                          <validation-provider
                            #default="{ errors }"
                            name="ThingLink"
                            :vid="`ThingLink${subTopicIndex}`"
                            rules="required"
                          >
                          <b-form-group 
                            label="ThingLink *" 
                            label-for="ThingLink"
                          >
                            <b-form-textarea
                              id="textarea-default"
                              v-model="form.subTopics[subTopicIndex].ThingLink"
                              placeholder="Enter embedded ThingLink"
                              class="custom-field"
                              style="height:90px;"
                            ></b-form-textarea>
                          </b-form-group>
                            <small class="text-danger float-left">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!-- open question -->
                        <b-col md="12" class="mb-2" v-if="form.subTopics[subTopicIndex].type == 3">
                          
                          <validation-provider
                            #default="{ errors }"
                            name="Question"
                            :vid="`Question${subTopicIndex}`"
                            rules="required"
                          >
                            <b-form-group label="Question *" label-for="item-name">
                              <!-- {{ form.subTopics[subTopicIndex].open_questions }} -->
                              <b-form-input
                                id="item-name"
                                class="custom-field"
                                type="text"
                                v-model="form.subTopics[subTopicIndex].open_questions"
                                placeholder="Question"
                              />
                            </b-form-group>
                            <small class="text-danger float-left">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!-- Video -->
                        <b-col
                          md="12"
                          class="mb-2"
                          v-if="form.subTopics[subTopicIndex].type == 1 && videoFlag"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="video"
                            :vid="`video${subTopicIndex}`"
                            rules="required"
                          >
                            <b-form-group label="Video *" label-for="video">
                              <i
                                :class="form.subTopics[subTopicIndex].type == 1 ? 'fa-solid fa-upload text-primary': 'd-none'"
                                style="margin-bottom: -27px;margin-left: 10px"
                              ></i>
                              <b-form-file
                                :id="`video${subTopicIndex}`"
                                v-model="form.subTopics[subTopicIndex].video"
                                :state="Boolean(form.subTopics[subTopicIndex].video)"
                                accept="video/*"
                                class="custom-field ml-25"
                                placeholder="Upload Video"
                              />
                              <small class="text-danger float-left">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          md="12"
                          class="mb-2"
                          v-if=" item && item.video_path && videoFlag == false"
                        >
                          <b-row class="w-100">
                            <b-col md="4"></b-col>
                            <b-col md="4">
                              <div
                                class="d-flex align-items-center mb-1 cursor-pointer"
                                @click="videoFlag = true"
                              >
                                <feather-icon
                                  :tooltip="'none'"
                                  icon="EditIcon"
                                  size="20"
                                  class="cursor-pointer text-link mr-1"
                                  v-b-tooltip.hover.top="'Edit Video'"
                                />
                                <small>Edit Video</small>
                              </div>
                              <video width="100%" height="auto" controls>
                                <source :src="item.video_path" type="video/mp4" />
                                <source :src="item.video_path" type="video/ogg" />Your browser does not support the video tag.
                              </video>
                            </b-col>
                            <b-col md="4"></b-col>
                          </b-row>
                        </b-col>
                        <!-- MCQ questions -->
                        <b-col
                          md="12"
                          v-if="form.subTopics[subTopicIndex].type == 1 || form.subTopics[subTopicIndex].type == 2"
                        >
                          <b-row
                            ref="row2"
                            class="px-1"
                            v-for="(question, questionIndex) in form.subTopics[subTopicIndex].MCQ"
                            :id="question.id"
                            :key="question.id"
                          >
                            <b-col md="12" class="mb-2">
                              <validation-provider
                                #default="{ errors }"
                                name="Question"
                                :vid="`Question${subTopicIndex+'-'+questionIndex}`"
                                :rules="form.subTopics[subTopicIndex].MCQ[questionIndex].question ? 'required' : ''"
                              >
                                <b-form-group label="Question *" label-for="item-name">
                                  <b-form-input
                                    id="item-name"
                                    class="custom-field"
                                    type="text"
                                    v-model="form.subTopics[subTopicIndex].MCQ[questionIndex].question"
                                    placeholder="Question"
                                  />
                                </b-form-group>
                                <small class="text-danger float-left">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>

                            <b-col md="4" lg="4" xl="4" class="mb-2">
                              <validation-provider
                                #default="{ errors }"
                                name="Answer 1"
                                :vid="`answer1${subTopicIndex+'-'+questionIndex}`"
                                :rules="{
                                      required: !!form.subTopics[subTopicIndex].MCQ[questionIndex].question,
                                      unique: { answers: form.subTopics[subTopicIndex].MCQ[questionIndex].options }
                                    }"
                              >
                                <b-form-group label="Answer 1 (correct answer) *" label-for="answer1">
                                  <b-form-input
                                    id="answer1"
                                    type="text"
                                    class="custom-field"
                                    v-model="form.subTopics[subTopicIndex].MCQ[questionIndex].options[0]"
                                    placeholder="Answer 1"
                                  />
                                </b-form-group>
                                <small class="text-danger float-left">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col md="4" lg="4" xl="4" class="mb-2">
                              <validation-provider
                                #default="{ errors }"
                                name="Answer 2"
                                :vid="`answer2${subTopicIndex+'-'+questionIndex}`"
                                :rules="{
                                      required: !!form.subTopics[subTopicIndex].MCQ[questionIndex].question,
                                      unique: { answers: form.subTopics[subTopicIndex].MCQ[questionIndex].options }
                                    }"
                              >
                                <b-form-group label="Answer 2 *" label-for="answer2">
                                  <b-form-input
                                    id="answer2"
                                    class="custom-field"
                                    type="text"
                                    v-model="form.subTopics[subTopicIndex].MCQ[questionIndex].options[1]"
                                    placeholder="Answer 2"
                                  />
                                </b-form-group>
                                <small class="text-danger float-left">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col md="4" lg="4" xl="4" class="mb-2">
                              <validation-provider
                                #default="{ errors }"
                                name="Answer 3"
                                :vid="`answer3${subTopicIndex+'-'+questionIndex}`"
                                :rules="{
                                      required: !!form.subTopics[subTopicIndex].MCQ[questionIndex].question,
                                      unique: { answers: form.subTopics[subTopicIndex].MCQ[questionIndex].options }
                                    }"
                              >
                                <b-form-group label="Answer 3 *" label-for="answer3">
                                  <b-form-input
                                    id="answer3"
                                    class="custom-field"
                                    type="text"
                                    v-model="form.subTopics[subTopicIndex].MCQ[questionIndex].options[2]"
                                    placeholder="Answer 3"
                                  />
                                </b-form-group>
                                <small class="text-danger float-left">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col md="4" lg="4" xl="4" class="mb-2">
                              <validation-provider
                                #default="{ errors }"
                                name="Answer 4"
                                :vid="`answer4${subTopicIndex+'-'+questionIndex}`"
                                :rules="{
                                      required: !!form.subTopics[subTopicIndex].MCQ[questionIndex].question,
                                      unique: { answers: form.subTopics[subTopicIndex].MCQ[questionIndex].options }
                                    }"
                              >
                                <b-form-group label="Answer 4 *" label-for="answer4">
                                  <b-form-input
                                    id="answer4"
                                    class="custom-field"
                                    type="text"
                                    v-model="form.subTopics[subTopicIndex].MCQ[questionIndex].options[3]"
                                    placeholder="Answer 4"
                                  />
                                </b-form-group>
                                <small class="text-danger float-left">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col
                              md="4"
                              lg="4"
                              xl="4"
                              v-if="form.subTopics[subTopicIndex].type == 1"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Time"
                                :vid="`time${subTopicIndex+'-'+questionIndex}`"
                                :rules="form.subTopics[subTopicIndex].MCQ[questionIndex].question ? 'required' : ''"
                              >
                                <b-form-group label="Time *" label-for="time">
                                  <flat-pickr
                                    id="time"
                                    v-model="form.subTopics[subTopicIndex].MCQ[questionIndex].video_time"
                                    :config="{ enableTime: true, noCalendar: true, enableSeconds: true, time_24hr: true}"
                                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                                    placeholder="Hour : minute : second"
                                  />
                                </b-form-group>
                                <small class="text-danger float-left">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col md="2" class="mb-50">
                              <div class="w-100 d-flex justify-content-end">
                                <b-button
                                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                  variant="outline-danger"
                                  class="mt-0 mt-md-2 rounded-pill"
                                  @click="removeQuestion(subTopicIndex,questionIndex)"
                                >
                                  <feather-icon icon="XIcon" class="mr-25" />
                                  <span>Delete</span>
                                </b-button>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row class="px-1">
                            <b-col md="12">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-primary"
                                class="rounded-pill"
                                @click="newQuestion(subTopicIndex)"
                              >
                                <feather-icon icon="PlusIcon" class="mr-25" />
                                <span>New Question</span>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-row>
                  <b-row v-if="form.subTopics[0].type == 3">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="rounded-pill"
                      @click="newSubTopic(form.subTopics[0].type)"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>New Sub-Topic</span>
                    </b-button>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
            <div class="w-100 d-flex justify-content-center">
              <b-button
                variant="outline-secondary"
                class="rounded-pill mt-2 mr-1"
                @click="$router.go(-1)"
              >Cancel</b-button>
              <b-button
                variant="primary"
                :class="uploadProgress && uploadProgress != 100 ? 'rounded-pill mt-2 cursor-disabled' : 'rounded-pill mt-2'"
                type="submit"
                :disabled="uploadProgress && uploadProgress != 100 ? true : false"
              >Submit</b-button>
            </div>
          </b-form>
        </b-col>
        <b-col md="1"></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormTags,
  BFormRadioGroup,
  BFormRadio,
  VBTooltip,
  BToast,
  BProgress,
  BForm
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "@validations";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";
extend("unique", {
  validate(value, { answers }) {
    return answers.filter(answer => answer === value).length === 1;
  },
  params: ["answers"],
  message: "The {_field_} must be unique."
});
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
import { quillEditor } from 'vue-quill-editor'
// import { codeSnow } from './code'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormTags,
    BFormRadioGroup,
    BFormRadio,
    BFormFile,
    BToast,
    BProgress,
    VBTooltip,
    vSelect,
    flatPickr,
    required,
    quillEditor
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  mixins: [heightTransition],
  computed: {
    ...mapGetters({
      item: "topics/item",
      modules: "modules/lookups",
      subTopicsLookups: "topics/subTopicsLookups",
      uploadProgress: "app/uploadProgress"
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    module_id() {
      return this.$route.query.module_id ? this.$route.query.module_id : null;
    }
  },
  watch:{
    'form.name'(){
    }
  },
  data() {
    return {
      editCoverImage: false,
      editImage:[],
      editPDF:[],
      snowOption: {
        modules: {
          toolbar: '#toolbar',
        },
        theme: 'snow',
      },
      showToast: false,
      nextTodoId: 2,
      editCoverPhoto: false,
      videoFlag: true,
      form: {
        name: null,
        description: null,
        module_id: null,
        cover_image: null,
        subTopics: [
          {
            type: null,
            video: null,
            pdf:null,
            attach:null,
            image:null,
            text:null,
            ThingLink:null,
            open_questions: [],
            MCQ: [
              {
                question: null,
                options: [],
                video_time: null
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    edit_image(index){
      this.$set(this.editImage, index, true);
    },
    edit_pdf(index){
      this.$set(this.editPDF, index, true);
      console.log('--------------------this.editPDF[index]',this.editPDF[index]);
      
    },
    newSubTopic(checkValue) {
      this.form.subTopics.push({
        type: checkValue == 3 ? 3 : null,
        video: null,
        open_question: null,
        MCQ: [
          {
            question: null,
            options: [],
            video_time: null
          }
        ]
      });
    },
    newQuestion(subTopicIndex) {
      this.form.subTopics[subTopicIndex].MCQ.push({
        question: null,
        options: [],
        video_time: null
      });
    },
    removeSubTopic(index) {
      this.form.subTopics.splice(index, 1);
    },
    removeQuestion(subTopicIndex, questionIndex) {
      this.form.subTopics[subTopicIndex].MCQ.splice(questionIndex, 1);
    },
    initTrHeight() {
      this.trSetHeight(null);
    },
    init() {
      this.$store.dispatch("topics/subTopicsLookups");
      this.$store.dispatch("modules/lookups").then(_ => {
        this.form.subTopics.pop();
        this.form.subTopics = [
          {
            type: null,
            video: null,
            open_question: null,
            MCQ: [
              {
                question: null,
                options: [],
                video_time: null
              }
            ]
          }
        ];
        if (this.id) {
          this.$store.dispatch("topics/get", this.id).then(_ => {
            this.form.id = this.item.id;
            this.form.name = this.item.name;
            this.form.cover_image = this.item.cover_image;
            this.form.module_id = this.item.module_id;
            this.form.description = this.item.description;
            this.form.subTopics = this.item.subTopics;
            this.videoFlag = this.item.video_path ? false : true;
            if (this.item.type != 3) {
              this.form.subTopics.forEach((item, sub_index) => {
                this.editImage[sub_index] = false;
                item.MCQ.forEach((mcqItem, mcq_index) => {
                  const options = mcqItem.options;
                  this.form.subTopics[sub_index].MCQ[
                    mcq_index
                  ].options = options.map(option => option.value);
                });
              });
            }
          });
        }
      });
    },
    convertTimeToSeconds(timeString) {
      const parts = timeString.split(":");
      let seconds = 0;
      if (parts.length === 2) {
        // MM:SS format
        seconds = parseInt(parts[0]) * 60 + parseInt(parts[1]);
      } else if (parts.length === 3) {
        // HH:MM:SS format
        seconds =
          parseInt(parts[0]) * 3600 +
          parseInt(parts[1]) * 60 +
          parseInt(parts[2]);
      }
      return seconds;
    },
    submit() {
      this.$refs.TopicForm.validate().then(success => {
        if (success) {
          this.showToast = true;

          if (this.id) {
            this.form.id = this.id;
            const formData = new FormData();
            // Append simple fields
            formData.append("id", this.form.id);
            formData.append("description", this.form.description);
            formData.append("module_id", this.form.module_id);
            formData.append("name", this.form.name);
            formData.append("cover_image", this.form.cover_image);
            // Handle subTopics
            this.form.subTopics.forEach((subtopic, index) => {
              formData.append(`subTopics[${index}][id]`,subtopic.id);
              formData.append(`type`, subtopic.type);
              if (subtopic.type === 1 && subtopic.video instanceof File) {
                formData.append(`video`, subtopic.video);
              }
              // Handle other subtopic fields
              formData.append(`subTopics[${index}][type]`, subtopic.type);
              if (subtopic.type === 1 || subtopic.type === 2) {
                subtopic.MCQ.forEach((mcq, mcqIndex) => {
                  formData.append(
                    `subTopics[${index}][MCQ][${mcqIndex}][id]`,
                    mcq.id
                  );
                  formData.append(
                    `subTopics[${index}][MCQ][${mcqIndex}][question]`,
                    mcq.question
                  );
                  mcq.options.forEach((option, optionIndex) => {
                    formData.append(
                      `subTopics[${index}][MCQ][${mcqIndex}][options][${optionIndex}]`,
                      option
                    );
                  });
                  formData.append(
                    `subTopics[${index}][MCQ][${mcqIndex}][video_time]`,
                    mcq.video_time
                  );
                });
              }
              if (subtopic.type === 3) {
                formData.append(
                  `subTopics[${index}][openQuestion]`,
                  subtopic.open_questions
                );
              }
              if (subtopic.type === 4) {
                formData.append(
                  `subTopics[${index}][image]`,
                  subtopic.image
                );
              }
              if (subtopic.type === 5) {
                formData.append(
                  `subTopics[${index}][text]`,
                  subtopic.text
                );
              }
              if (subtopic.type === 6  && this.editPDF[index]) {
                formData.append(
                  `subTopics[${index}][pdf]`,
                  subtopic.pdf
                );
                formData.append(
                  `subTopics[${index}][attach]`,
                  subtopic.attach
                );
              }
              if (subtopic.type === 7) {
                formData.append(
                  `subTopics[${index}][ThingLink]`,
                  subtopic.ThingLink
                );
              }
            });

            this.$store
              .dispatch("topics/update", formData)
              .then(res => {
                this.showToast = false;
                this.$router.go(-1);
              })
              .catch(error => {
                this.showToast = false;
                if (error.response) {
                  this.$swal({
                    icon: "error",
                    title: `<h4>${error.response.data.error}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: "#E84185",
                    allowOutsideClick: true
                  });
                }
              });
          } else {
            const formData = new FormData();
            // Append simple fields
            formData.append("description", this.form.description);
            formData.append("module_id", this.form.module_id);
            formData.append("name", this.form.name);
            formData.append("cover_image", this.form.cover_image);
            // Handle subTopics
            this.form.subTopics.forEach((subtopic, index) => {
              formData.append(`type`, subtopic.type);
              if (subtopic.type === 1 && subtopic.video instanceof File) {
                formData.append(`video`, subtopic.video);
              }
              // Handle other subtopic fields
              formData.append(`subTopics[${index}][type]`, subtopic.type);
              if (subtopic.type === 1 || subtopic.type === 2) {
                subtopic.MCQ.forEach((mcq, mcqIndex) => {
                  formData.append(
                    `subTopics[${index}][MCQ][${mcqIndex}][question]`,
                    mcq.question
                  );
                  mcq.options.forEach((option, optionIndex) => {
                    formData.append(
                      `subTopics[${index}][MCQ][${mcqIndex}][options][${optionIndex}]`,
                      option
                    );
                  });
                  formData.append(
                    `subTopics[${index}][MCQ][${mcqIndex}][video_time]`,
                    mcq.video_time
                  );
                });
              }
              if (subtopic.type === 3) {

                formData.append(
                  `subTopics[${index}][openQuestion]`,
                  subtopic.open_questions
                );
              }
              if (subtopic.type === 4) {
                formData.append(
                  `subTopics[${index}][image]`,
                  subtopic.image
                );
              }
              if (subtopic.type === 5) {
                formData.append(
                  `subTopics[${index}][text]`,
                  subtopic.text
                );
              }
              if (subtopic.type === 6) {
                formData.append(
                  `subTopics[${index}][pdf]`,
                  subtopic.pdf
                );
                formData.append(
                  `subTopics[${index}][attach]`,
                  subtopic.attach
                );
              }
              if (subtopic.type === 7) {
                formData.append(
                  `subTopics[${index}][ThingLink]`,
                  subtopic.ThingLink
                );
              }
            });
            this.$store
              .dispatch("topics/put", formData)
              .then(res => {
                this.showToast = false;
                this.$router.go(-1);
              })
              .catch(error => {
                this.showToast = false;
                if (error.response) {
                  this.$swal({
                    icon: "error",
                    title: `<h4>${error.response.data.error}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: "#E84185",
                    allowOutsideClick: true
                  });
                }
              });
          }
        }
      });
    }
  },
  mounted() {
    this.init();

  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/libs/quill.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.flat-picker {
  border-bottom: 1px #d8d6de solid !important;
}

textarea.form-control {
  height: 40px ;
  resize: vertical;
  overflow-y: auto; 
  padding: 5px; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}
</style>
